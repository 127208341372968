<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-files"> </i> 档案管理 | 回收站</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <el-select v-model="grid.sea.AID" @change="filter" clearable placeholder="请选择项目">
                                <el-option v-for="item in archiveList" :key="item.Key" :value="item.Key"
                                    :label="item.Value" />
                            </el-select>
                            <el-select v-model="grid.sea.TYPE" @change="filter" clearable placeholder="请选择类型">
                                <el-option label="目录" :value="1" />
                                <el-option label="文件" :value="2" />
                            </el-select>
                            <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE"
                            @page-changed="loadPage">
                            <el-table-column prop="Name" label="名称" width="200" />
                            <el-table-column prop="Path" label="路径" width="300" />
                            <el-table-column label="类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.Type==1" type="success">目录</el-tag>
                                    <el-tag v-else type="warning">文件</el-tag>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="ArchiveName" label="项目" width="150" align="center" /> -->
                            <el-table-column prop="TeacherName" label="操作人" width="100" align="center" />
                            <el-table-column prop="DeleteTime" label="删除日期" width="150" align="center" />
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <el-button type="text" icon="el-icon-document"
                                        @click="rowLock(scope.row)">还原</el-button>
                                    <el-button type="text" icon="el-icon-delete"
                                        @click="rowDel(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column />
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

    import Wgrid from "@/components/wgrid";
    import Swal from "sweetalert2";
    export default {
        name: "index",
        components: { Wgrid },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                    },
                    ls: [],
                    total: 0,
                    loading: false
                },
                archiveList: []
            }
        },
        created() {
            this.getArchives();
            this.getList();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
            },
            loadPage(pi) {
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.getList();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getCollection({
                    url: "/api/School/DOC/ContentApi/GetRecycleds",
                    data: this.grid.sea,
                    completed: function (its, n) {
                        self.grid.ls = its;
                        self.grid.total = n;
                        self.grid.loading = false;
                    }
                })
            },
            getArchives() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/ArchiveApi/GetArchives",
                    completed: function (its) {
                        console.log(its.DATA)
                        self.archiveList = its.DATA;
                    }
                })
            },

            rowDel(r) {
                let self = this;
                Swal.fire({
                    text: "删除后不可再找回，确定要删除吗？",
                    icon: "question",
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    showCancelButton: true,
                }).then((v) => {
                    if (v.isConfirmed) {
                        self.whale.remote.getResult({
                            url: "/api/School/DOC/ContentApi/TrueDelete",
                            data: { ID: r.Id },
                            completed: function () {
                                // self.filter();
                                self.whale.toast.info("删除成功")
                                self.grid.ls = self.grid.ls.filter(o => o.Id != r.Id)
                                self.grid.total -= 1
                            }
                        })
                    }
                })
            },
            rowLock(r) {
                let self = this;
                Swal.fire({
                    text: "确定要还原这个项目吗？",
                    icon: "question",
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    showCancelButton: true,
                }).then((v) => {
                    if (v.isConfirmed) {
                        this.whale.remote.getResult({
                            url: "/api/School/DOC/ContentApi/RestoreDelete",
                            data: { ID: r.Id },
                            completed: function () {
                                //self.filter();
                                self.whale.toast.info("还原成功")
                                self.grid.ls = self.grid.ls.filter(o => o.Id != r.Id)
                                self.grid.total -= 1
                            }
                        })
                    }
                })
            },
        }
    }
</script>